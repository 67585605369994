.f3-contact {
  grid-column: 1 / span 4;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 48px;
  column-gap: 24px;

    /* Tablet */
    @media (min-width: 769px) {
      row-gap: 80px;
    }

  /* Desktop */
  @media (min-width: 1280px) {
    grid-column: 1 / span 12;
    grid-template-columns: repeat(12, 1fr);
  }
}

.f3-contact-image-and-text {
  display: inherit;
  grid-column: 1 / span 4;
  row-gap: 48px;

  /* Tablet */
  @media (min-width: 769px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 24px;
    grid-column: 1 / span 3;
  }

  /* Desktop */
  @media (min-width: 1280px) {
    grid-template-columns: repeat(4, 1fr);
    grid-column: 2 / span 4;
    row-gap: 48px;
  }
}

.f3-contact-image {
  margin-top: 42px;
  grid-column: 2 / span 2;

  /* Tablet */
  @media (min-width: 769px) {
    grid-column: 1 / span 1;
  }

  /* Desktop */
  @media (min-width: 1280px) {
    max-width: 280px;
    grid-column: 1 / span 2;
  }
}

.f3-contact-text {
  display: flex;
  grid-column: 1 / span 4;

  /* Tablet */
  @media (min-width: 769px) {
    margin-bottom: 0;
    grid-column: 2 / span 2;
  }
  /* Desktop */
  @media (min-width: 1280px) {
    grid-column: 1 / span 4;
  }
}

.f3-contact-text .c2-text {
  display: block;
  margin: 0;
  align-self: center;
}

.f3-contact-form {
  display: grid;
  margin-bottom: 155px;
  gap: 24px;
  grid-template-columns: repeat(1, 1fr);
  grid-column: 1 / span 4;
  align-self: end;

  /* Tablet */
  @media (min-width: 769px) {
    margin-bottom: 0;
    grid-template-columns: repeat(4, 1fr);
  }
  /* Desktop */
  @media (min-width: 1280px) {
    grid-column: 6 / span 6;
  }
}
