.c6-timeline {
  width: 100vw;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column: 1 / span 4;

  /* Desktop */
  @media (min-width: 1280px) {
    grid-template-columns: repeat(12, 1fr);
    grid-column: 1 / span 12;
  }

  /* XLDesktop */
  @media (min-width: 1440px) {
    width: auto;
  }
}

.c6-timeline-headline {
  grid-column: 1 / span 4;
  margin: 0 16px 48px 16px;

  /* Tablet */
  @media (min-width: 769px) {
    margin: 0 32px 48px 32px;
  }

  /* Desktop */
  @media (min-width: 1280px) {
    margin: 0 80px 48px 80px;
    grid-column: 2 / span 11;
  }
}

.c6-timeline-item {
  display: grid;
  cursor: pointer;
  grid-template-columns: repeat(4, 1fr);
  text-align: center;
  grid-column: 1 / span 4;
  position: relative;
  border-bottom: 1px solid var(--color-black);
  margin-bottom: 16px;

  /* Desktop */
  @media (min-width: 1280px) {
    grid-template-columns: repeat(12, 1fr);
    grid-column: 1 / span 12;
  }
}

/* Desktop */
@media (min-width: 1280px) {
  .c6-timeline-item:hover {
    .c6-timeline-item-arrow {
      opacity: 1;
      transition: all 0.3s ease-in-out;
    }
  }
}

.c6-timeline-item-titles {
  display: flex;
  flex-direction: column;
  gap: 8px;

  grid-column: 1 / span 4;

  /* Desktop */
  @media (min-width: 1280px) {
    grid-column: 5 / span 4;
  }
}

.c6-timeline-item-duration {
  margin-bottom: 24px;
}

.c6-timeline-item-text {
  grid-column: 1 / span 4;
  max-height: 0;
  overflow: hidden;
  padding-bottom: 0px;
  transition: max-height 0.3s ease, padding 0.3s ease;
  margin: 0 24px;

  /* Desktop */
  @media (min-width: 1280px) {
    margin: 0;
    grid-column: 3 / span 8;
  }

  div p {
    margin: 0;
  }
}

.c6-timeline-item-text.expanded {
  max-height: 100%;
  padding-bottom: 64px;
}

.c6-timeline-item-arrow {
  opacity: 1;
  cursor: pointer;
  position: absolute;
  right: 16px;
  bottom: 24px;
  transform: rotate(90deg);
  transition: all 0.3s ease-in-out;

  /* Desktop */
  @media (min-width: 1280px) {
    opacity: 0;
  }
}
.c6-timeline-item-arrow.active {
  transform: rotate(-90deg);
  transition: transform 0.3s ease-in-out;
}
