@font-face {
  font-family: "JosefinSans Light";
  font-display: swap;
  src: url("./JosefinSans-Light.woff") format("woff");
}

@font-face {
  font-family: "JosefinSans Bold";
  font-display: swap;
  src: url("./JosefinSans-Bold.ttf") format("ttf");
}
