.c3-text-image {
  grid-column: 1 / span 4;

  /* Desktop */
  @media (min-width: 1280px) {
    grid-column: 3 / span 8;
  }
}

.image-first {
  .image-block {
    order: 1;
  }
  .text-block {
    order: 2;
  }
}

.text-first {
  .image-block {
    order: 2;
  }
  .text-block {
    order: 1;
  }
}

.image-block {
  position: relative;
  .c4-image-tag {
    position: absolute;
    top: 8px;
    right: 8px;
    padding: 4px 16px;
    border-radius: 50px;
    background-color: var(--color-white);
  }
}

.text-block {
  a {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
  }
}

/* SMALL */
.c3-block-image-s {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  grid-column: 1 / span 4;

  /* Tablet */
  @media (min-width: 769px) {
    grid-column: 1 / span 2;
  }

  /* Desktop */
  @media (min-width: 1280px) {
    grid-column: 2 / span 4;
  }

  .image-block {
    padding-bottom: 24px;
  }
}
.c3-block-text-s {
  display: flex;
  flex-direction: column;
  grid-column: 1 / span 4;

  .text-first {
    .image-block {
      order: 2;
    }
    .text-block {
      order: 1;
    }
  }

  /* Tablet */
  @media (min-width: 769px) {
    grid-column: 3 / span 2;
  }

  /* Desktop */
  @media (min-width: 1280px) {
    grid-column: 8 / span 4;
  }

  .text-block {
    padding-bottom: 24px;
  }
}

/* MEDIUM */
.c3-block-image-m {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  grid-column: 1 / span 4;

  /* Tablet */
  @media (min-width: 769px) {
    grid-column: 1 / span 2;
  }

  /* Desktop */
  @media (min-width: 1280px) {
    display: inherit;
    grid-column: 4 / span 8;
    gap: 24px;
    grid-template-columns: repeat(8, 1fr);

    .image-block {
      grid-column: 1 / span 4;
    }
    .text-block {
      grid-column: 6 / span 3;
      align-self: center;
    }
  }

  .image-block {
    padding-bottom: 24px;
  }
}
.c3-block-text-m {
  display: flex;
  flex-direction: column;
  grid-column: 1 / span 4;

  /* Tablet */
  @media (min-width: 769px) {
    grid-column: 3 / span 2;
  }

  /* Desktop */
  @media (min-width: 1280px) {
    display: inherit;
    grid-column: 2 / span 8;
    gap: 24px;
    grid-template-columns: repeat(8, 1fr);

    .image-block {
      grid-column: 5 / span 4;
    }
    .text-block {
      grid-column: 1 / span 3;
      align-self: center;
    }
  }

  .text-block {
    padding-bottom: 24px;
  }
}

/* LARGE */
.c3-block-image-l {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  grid-column: 1 / span 4;

  /* Tablet */
  @media (min-width: 769px) {
    display: inherit;
    gap: 24px;
    grid-column: 1 / span 4;
    grid-template-columns: repeat(4, 1fr);

    .image-block {
      grid-column: 1 / span 2;
    }
    .text-block {
      grid-column: 3 / span 2;
    }
  }

  /* Desktop */
  @media (min-width: 1280px) {
    grid-column: 1 / span 12;
    grid-template-columns: repeat(12, 1fr);

    .image-block {
      grid-column: 2 / span 4;
    }
    .text-block {
      grid-column: 7 / span 5;
      align-self: center;
    }
  }

  .image-block {
    padding-bottom: 24px;
  }
}
.c3-block-text-l {
  display: flex;
  flex-direction: column;
  grid-column: 1 / span 4;

  /* Tablet */
  @media (min-width: 769px) {
    display: inherit;
    gap: 24px;
    grid-column: 1 / span 4;
    grid-template-columns: repeat(4, 1fr);

    .image-block {
      grid-column: 3 / span 2;
    }
    .text-block {
      grid-column: 1 / span 2;
      align-self: center;
    }
  }

  /* Desktop */
  @media (min-width: 1280px) {
    grid-column: 1 / span 12;
    grid-template-columns: repeat(12, 1fr);

    .image-block {
      grid-column: 8 / span 4;
    }
    .text-block {
      grid-column: 2 / span 5;
    }
  }

  .text-block {
    padding-bottom: 24px;
  }
}

/* XLARGE */
.c3-block-image-xl {
  display: inherit;
  grid-column: 1 / span 4;

  /* Tablet */
  @media (min-width: 769px) {
    .text-block {
      margin-top: 32px;
    }
  }

  /* Desktop */
  @media (min-width: 1280px) {
    grid-column: 1 / span 12;
    grid-template-columns: repeat(12, 1fr);

    .image-block {
      grid-column: 1 / span 12;
    }

    .text-block {
      grid-column: 2 / span 10;
    }
  }
}
.c3-block-text-xl {
  display: none;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  grid-column: 1 / span 4;

  /* Tablet */
  @media (min-width: 769px) {
    .image-block {
      margin-top: 32px;
    }
  }

  /* Desktop */
  @media (min-width: 1280px) {
    grid-column: 1 / span 12;
  }
}

.c4-image-s {
  display: flex;
  aspect-ratio: 3/4;
}
.c4-text-s {
  display: flex;
  aspect-ratio: 1/1;

  /* Tablet */
  @media (min-width: 769px) {
    aspect-ratio: 4/3;
  }
}

.c4-image-m {
  display: flex;
  aspect-ratio: 3/4;

  /* Desktop */
  @media (min-width: 1280px) {
    aspect-ratio: 1/1;
  }
}
.c4-text-m {
  display: flex;
  aspect-ratio: 1/1;

  /* Tablet */
  @media (min-width: 769px) {
    aspect-ratio: 4/3;
  }

  /* Desktop */
  @media (min-width: 1280px) {
    aspect-ratio: 1/1;
  }
}
.c4-image-l {
  display: flex;
  aspect-ratio: 3/4;

  /* Desktop */
  @media (min-width: 1280px) {
    aspect-ratio: 1/1;
  }
}
.c4-text-l {
  display: flex;
  aspect-ratio: 1/1;

  /* Desktop */
  @media (min-width: 1280px) {
    aspect-ratio: 3/4;
  }
}

.c4-image-xl {
  display: flex;
  aspect-ratio: 9/16;

  /* Tablet */
  @media (min-width: 769px) {
    aspect-ratio: 16/9;
  }
}

.c4-text-xl {
  display: flex;
  aspect-ratio: 9/16;

  /* Tablet */
  @media (min-width: 769px) {
    aspect-ratio: 16/9;
  }
}

.c3-text-image-headline {
  margin: 16px 0 24px 0;
}
