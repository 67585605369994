.l1-projects {
  grid-column: span 4;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 80px;
  column-gap: 24px;

  /* Desktop */
  @media (min-width: 1280px) {
    row-gap: 96px;
    grid-template-columns: repeat(12, 1fr);
    grid-column: span 12;
  }

  .l1-projects-intro {
    grid-column: span 4;

    /* Desktop */
    @media (min-width: 1280px) {
      grid-column: 3 / span 8;
    }

    h2 {
      margin: 0 0 16px 0;
    }
    .copy-default {
      p {
        margin: 0;
      }
    }
    .copy-default,
    h2 {
      grid-column: span 4;
      text-align: center;

      /* Desktop */
      @media (min-width: 1280px) {
        grid-column: span 12;
      }
    }
  }

  .c3-text-image {
    .image-block {
      width: 100%;
    }
  }
}
