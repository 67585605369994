.c8-image-gallery {
  display: flex;
  grid-column: span 4;
  gap: 24px;
  flex-wrap: wrap;

  /* Tablet */
  @media (min-width: 769px) {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }

  /* Desktop */
  @media (min-width: 1280px) {
    grid-column: 2 / span 10;
  }

  .gallery-group-1 {
    grid-column: span 4;

    /* Tablet */
    @media (min-width: 769px) {
      grid-column: span 2;
    }

    .gallery-image-1 {
      cursor: pointer;
      padding-bottom: 24px;
      .c4-image {
        aspect-ratio: 1.34 / 1;
      }
    }
    .gallery-image-2 {
      cursor: pointer;
      .c4-image {
        aspect-ratio: 1 / 1;
      }
    }
  }
  .gallery-group-2 {
    grid-column: span 4;

    /* Tablet */
    @media (min-width: 769px) {
      grid-column: span 2;
    }

    .gallery-image-3 {
      cursor: pointer;
      height: 100%;
      .c4-image {
        aspect-ratio: 9/16;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
