.f1-header {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 16px;
  background: white;
  position: sticky;
  top: 0px;
  z-index: 20;

  /* Desktop */
  @media (min-width: 1280px) {
    grid-column: 1 / span 12;
  }
}

.f1-wrapper {
  grid-column: span 4;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  max-width: 1440px;
  margin: 0 auto;
  z-index: 20;
  gap: 20px;

  a {
    height: 100%;
  }
}

.f1-header-logo {
  display: flex;
  align-items: center;
  max-height: 40px;
}

.f1-header-logo div {
  font-size: 20px;
  margin-left: 8px;
}

.f1-header-nav-links-dropdown,
.f1-header-nav-links {
  display: flex;
  gap: 32px;
  font-size: 20px;
  align-items: baseline;
}

.f1-header-nav-links {
  flex-wrap: wrap;
  justify-content: end;
}
.f1-header-nav-links-dropdown {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  max-height: 0;
  transition: all 0.5s ease-in-out;

  position: absolute;
  top: 72px;
  background: white;
  overflow: hidden;
  box-shadow: 0px 15px 15px -4px rgba(0, 0, 0, 0.2);
  width: 100%;
}

.f1-header-nav-links-dropdown.open {
  padding: 30px 0;
}

.f1-header-nav-links-dropdown a {
  grid-column: span 4;
  padding: 0 15px 0 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.f1-header-nav-links-dropdown a .arrow-right {
  width: 24px;
}

.f1-header-nav-links-dropdown a,
.f1-header-nav-links a {
  font-weight: 100;
  text-transform: uppercase;
  text-decoration: none;
  color: #333;
  font-family: var(--font-family-light);
  position: relative;
}

.f1-header-nav-links-dropdown a.active:before,
.f1-header-nav-links a:hover:after {
  content: "";
  display: block;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background-color: #000;
  position: absolute;
}
.f1-header-nav-links a:hover:after {
  left: 50%;
  bottom: -5px;
}
.f1-header-nav-links-dropdown a.active:before {
  left: 2.5%;
  top: 50%;
  transform: translateY(-50%);
}
.f1-header-nav-links-dropdown-split {
  grid-column: span 4;
  width: 100%;
  height: 1px;
  background-color: #000;
}

.f1-header-nav-links-dropdown .active,
.f1-header-nav-links .active {
  font-family: var(--font-family-bold);
}

.f1-header-nav {
  margin-right: 20px;
}

.f1header-dropdown {
  position: relative;
  display: flex;
  align-items: center;
}

.f1header-dropdown-burger {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 40px;
}

.f1header-dropdown-burger img {
  position: absolute;
}

.f1header-dropdown-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #fff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  display: none;
  flex-direction: column;
}

.f1header-dropdown-dropdown a {
  padding: 12px 16px;
  text-decoration: none;
  color: #333;
}

.f1header-dropdown-dropdown a:hover {
  background-color: #f1f1f1;
}

.f1header-dropdown-show {
  width: 100%;
  display: flex;
}
