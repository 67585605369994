.f4-about {
  grid-column: span 4;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  
  /* Tablet */
  @media (min-width: 769px) {
    grid-template-columns: repeat(12, 1fr);
    grid-column: span 12;
  }
}
.f4-about-image {
  grid-column: 2 / span 2;
  margin: 16px;
  
  /* Tablet */
  @media (min-width: 769px) {
    margin: 0;
    grid-column: 2 / span 3;
    align-self: center;
  }

  /* Desktop */
  @media (min-width: 1280px) {
    margin: 48px 0px;
  }
}

.f4-about-text {
  margin-bottom: 48px;
  grid-column: 1 / span 4;

  /* Tablet */
  @media (min-width: 769px) {
    grid-column: 6 / span 6;
  }
}

.f4-about-text .c2-text {
  display: block;
  margin: 0;
}

.f4-about-text.grid-margin {
  /* Tablet */
  @media (min-width: 769px) {
    margin: 0 32px 0 0;
  }

  /* Desktop */
  @media (min-width: 1280px) {
    margin: 0 80px 0 0;
  }
}
