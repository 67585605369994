@import "./library.styles.css";

@import "./c1stage.styles.css";
@import "./c2text.styles.css";
@import "./c3textimage.styles.css";
@import "./c4image.styles.css";
@import "./c5imageslider.styles.css";
@import "./c6timeline.styles.css";
@import "./c7video.styles.css";
@import "./c8imagegallery.styles.css";
@import "./c9tags.styles.css";
@import "./c10filterdropdown.styles.css";

@import "./f1header.styles.css";
@import "./f2footer.styles.css";
@import "./f3contact.styles.css";
@import "./f4about.styles.css";
@import "./f5entrycard.styles.css";

@import "./t1teaser.styles.css";

@import "./l1projects.styles.css";

body {
  margin: 0;
}

.main-content-wrapper {
opacity: 0;
transition: opacity 0.2s ease-in-out 300ms;
}
.main-content-wrapper.fade-in {
  opacity: 1;
}

.main-content {
  min-height: 100vh;
  max-width: 1440px;
  margin: 0 auto;
}


.grid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 24px;
  row-gap: 80px;

  /* Tablet */
  @media (min-width: 769px) {
    height: 100%;
  }

  /* Desktop */
  @media (min-width: 1280px) {
    row-gap: 96px;
    /* padding: 80px 0; */
    grid-template-columns: repeat(12, 1fr);
  }

  /* XLDesktop */
  @media (min-width: 1920px) {
    max-width: 1440px;
    margin: 0 auto;
  }
}

.grid-margin {
  margin: 0 16px;

  @media (min-width: 769px) {
    margin: 0 32px;
  }

  @media (min-width: 1280px) {
    margin: 0 80px;
  }
}

/* Default for mobile */
.col-span-1 {
  grid-column: span 1 / span 1;
}
.col-span-2 {
  grid-column: span 2 / span 2;
}
.col-span-3 {
  grid-column: span 3 / span 3;
}
.col-span-4 {
  grid-column: span 4 / span 4;
}

.col-start-1 {
  grid-column-start: 1;
}

.col-start-2 {
  grid-column-start: 2;
}

.col-start-3 {
  grid-column-start: 3;
}

.col-start-4 {
  grid-column-start: 4;
}

/* Tablet */
@media (min-width: 769px) {
  .md-col-span-1 {
    grid-column: span 1 / span 1;
  }
  .md-col-span-2 {
    grid-column: span 2 / span 2;
  }
  .md-col-span-3 {
    grid-column: span 3 / span 3;
  }
  .md-col-span-4 {
    grid-column: span 4 / span 4;
  }
}

/* Desktop */
@media (min-width: 1280px) {
  .lg-col-span-1 {
    grid-column: span 1 / span 1;
  }
  .lg-col-span-2 {
    grid-column: span 2 / span 2;
  }
  .lg-col-span-3 {
    grid-column: span 3 / span 3;
  }
  .lg-col-span-4 {
    grid-column: span 4 / span 4;
  }
  .lg-col-span-5 {
    grid-column: span 5 / span 5;
  }
  .lg-col-span-6 {
    grid-column: span 6 / span 6;
  }
  .lg-col-span-7 {
    grid-column: span 7 / span 7;
  }
  .lg-col-span-8 {
    grid-column: span 8 / span 8;
  }
  .lg-col-span-9 {
    grid-column: span 9 / span 9;
  }
  .lg-col-span-10 {
    grid-column: span 10 / span 10;
  }
  .lg-col-span-11 {
    grid-column: span 11 / span 11;
  }
  .lg-col-span-12 {
    grid-column: span 12 / span 12;
  }

  .lg-col-start-1 {
    grid-column-start: 1;
  }

  .lg-col-start-2 {
    grid-column-start: 2;
  }

  .lg-col-start-3 {
    grid-column-start: 3;
  }

  .lg-col-start-4 {
    grid-column-start: 4;
  }

  .lg-col-start-5 {
    grid-column-start: 5;
  }

  .lg-col-start-6 {
    grid-column-start: 6;
  }

  .lg-col-start-7 {
    grid-column-start: 7;
  }

  .lg-col-start-8 {
    grid-column-start: 8;
  }

  .lg-col-start-9 {
    grid-column-start: 9;
  }

  .lg-col-start-10 {
    grid-column-start: 10;
  }

  .lg-col-start-11 {
    grid-column-start: 11;
  }

  .lg-col-start-12 {
    grid-column-start: 12;
  }
}

:root {
  /* SPACING */
  --spacing-xs: 8px;
  --spacing-sm: 16px;
  --spacing-md: 24px;
  --spacing-lg: 36px;
  --spacing-2lg: 48px;
  --spacing-3lg: 64px;
  --spacing-xl: 80px;
  --spacing-2xl: 96px;

  /* FONT SIZES */
  --font-size-tiny: 10px;
  --font-size-xs: 12px;
  --font-size-2xs: 13px;
  --font-size-sm: 14px;
  --font-size-2sm: 15px;
  --font-size-base: 16px;
  --font-size-lg: 18px;
  --font-size-xl: 20px;
  --font-size-2xl: 22px;
  --font-size-3xl: 24px;
  --font-size-4xl: 28px;
  --font-size-5xl: 30px;
  --font-size-6xl: 32px;
  --font-size-7xl: 34px;
  --font-size-8xl: 40px;
  --font-size-9xl: 48px;

  --font-size-h1: 48px;
  --font-size-h2: 36px;
  --font-size-h3: 28px;
  --font-size-h4: 20px;
  --font-size-h5: 18px;

  --line-height-h1: 57.6px;
  --line-height-h2: 43.2px;
  --line-height-h3: 33.6px;
  --line-height-h4: 24px;
  --line-height-h5: 21.6px;

  /* Desktop */
  @media (min-width: 1280px) {
    --font-size-h1: 64px;
    --font-size-h2: 48px;
    --font-size-h3: 36px;
    --font-size-h4: 28px;
    --font-size-h5: 20px;

    --line-height-h1: 64px;
    --line-height-h2: 48px;
    --line-height-h3: 36px;
    --line-height-h4: 28px;
    --line-height-h5: 20px;
  }

  /* COLORS */
  --color-black: #090909;
  --color-white: #ffffff;
  --color-neutral-grey: #f0f0f0;
  --color-neutral-middle-grey: #b5b5b5;
  --color-neutral-dark-grey: #505050;
  --color-primary-dark-blue: #1d3557;
  --color-primary-middle-blue: #809bce;
  --color-primary-light-blue: #e1ecf7;
  --color-secondary-dark-green: #007f5f;
  --color-secondary-light-green: #2b9348;
  --color-utils-error: #e5383b;
  --color-utils-success: #2b9348;
  --color-utils-warning: #f8961e;

  /* FONT FAMILY */
  --font-family-light: "JosefinSans Light", Arial, Sans-Serif;
  --font-family-bold: "JosefinSans Bold", Arial, Sans-Serif;
}

@font-face {
  font-family: "JosefinSans Light";
  font-display: swap;
  font-weight: 300;
  src: url("./JosefinSans-Light.woff") format("woff");
}

@font-face {
  font-family: "JosefinSans Bold";
  font-display: swap;
  font-weight: 700;
  src: url("./JosefinSans-Bold.ttf") format("ttf");
}

/* Mobile & Tablet */
h1 {
  font-size: var(--font-size-h1);
  line-height: var(--line-height-h1);
  font-family: var(--font-family-bold);
}

h2 {
  font-size: var(--font-size-h2);
  line-height: var(--line-height-h2);
  font-family: var(--font-family-bold);
}

h3 {
  font-size: var(--font-size-h3);
  line-height: var(--line-height-h3);
  font-family: var(--font-family-bold);
}

h4 {
  font-size: var(--font-size-h4);
  line-height: var(--line-height-h4);
  font-family: var(--font-family-bold);
  margin: 0 0 8px 0;
}

h5 {
  font-size: var(--font-size-h5);
  line-height: var(--line-height-h5);
  font-family: var(--font-family-bold);
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
}

.copy-large {
  font-size: var(--font-size-xl);
  line-height: var(--spacing-md);
  font-family: var(--font-family-light);
}

.copy-large-bold {
  font-size: var(--font-size-xl);
  line-height: 26px;
  font-family: var(--font-family-bold);
  font-weight: 700;
}

.copy-default {
  font-size: var(--font-size-base);
  line-height: 20.8px;
  font-family: var(--font-family-light);
}

.copy-default-bold {
  font-size: var(--font-size-base);
  line-height: 20.8px;
  font-family: var(--font-family-bold);
  font-weight: 700;
}

.copy-small {
  font-size: var(--font-size-xs);
  line-height: 15.6px;
  font-family: var(--font-family-light);
}

.copy-small-bold {
  font-size: var(--font-size-xs);
  line-height: 15.6px;
  font-family: var(--font-family-bold);
  font-weight: 700;
}

.copy-xsmall {
  font-size: 9px;
  line-height: 11.7px;
  font-family: var(--font-family-light);
  color: var(--color-neutral-middle-grey);
}
