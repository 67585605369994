.c10-filter-dropdown {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column: span 4;
  
  /* Desktop */
  @media (min-width: 1280px) {
    grid-template-columns: repeat(12, 1fr);
    grid-column: 4 / span 6;
  }

  h2 {
    margin: 0 0 16px 0;
  }

  .copy-default {
    margin-bottom: 64px;
    p {
      margin: 0;
    }
  }
  .copy-default,
  h2 {
    grid-column: span 4;
    text-align: center;

    /* Desktop */
    @media (min-width: 1280px) {
      grid-column: span 12;
    }
  }
}

.dropdown-wrapper {
  position: relative;
  border-bottom: 1px solid var(--color-neutral-middle-grey);
  height: 52px;
  grid-column: span 4;

  /* Desktop */
  @media (min-width: 1280px) {
    grid-column: 4 / span 6;
  }

  .copy-large-bold {
    text-align: center;
  }
}

.dropdown {
  cursor: pointer;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
}

.dropdown-arrow {
  position: absolute;
  right: 0;
  bottom: -5px;
  padding: 16px;
}

.dropdown-arrow-closed {
  transform: rotate(90deg);
}

.dropdown-arrow-opened {
  transform: rotate(-90deg);
}

.dropdown-list {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  overflow: hidden;
  transition: max-height 0.7s ease-in-out;
  background: var(--color-neutral-grey);
  display: flex;
  flex-direction: column;
  z-index: 1;
}

.dropdown-list-item.active:before,
.dropdown-list-item:hover:after {
  content: "";
  display: block;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background-color: #000;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -10px;
}

.dropdown-list-item {
  position: relative;
  cursor: pointer;
  margin: 24px 0 24px 20px;
  width: fit-content;
}

.dropdown-list-open {
  max-height: 2000px; /* Set a maximum height that is larger than the expected content height */
}

.dropdown-list-closed {
  max-height: 0;
}

.dropdown-wrapper,
.dropdown-list-open,
.dropdown-list-closed {
  grid-column: span 4;

  /* Desktop */
  @media (min-width: 1280px) {
    grid-column: 4 / span 6;
  }
}
