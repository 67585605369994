/* INPUT FIELD */
.input-field {
  width: 100%;
  border-bottom: 1px solid black;
  opacity: 0.8;
  position: relative;
  margin-bottom: 10px;
}
.input-field:hover {
  opacity: 1;
}

.input-field input {
  width: 100%;
  border: none;
}

.input-field-label {
  position: absolute;
  bottom: 0;
  pointer-events: none;
  font-family: var(--font-family-light);
  font-size: var(--font-size-base);
  color: var(--color-neutral-dark-grey);
  transition: all 0.3s ease-in-out;
}
.input-field-label-active {
  font-size: var(--font-size-tiny);
  color: var(--color-neutral-middle-grey);
  bottom: 25px;
}

/* BUTTON */
.library-button {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  font-size: var(--font-size-3xl);
  font-family: var(--font-family-bold);
  line-height: 24px;

  text-decoration: none;
  white-space: nowrap;
  max-height: 50px;
}

.library-button-primary-darkblue {
  padding: 15px 60px;
  background-color: var(--color-primary-dark-blue);
  color: var(--color-white);
}
.library-button-primary-darkblue:hover {
  background-color: var(--color-primary-middle-blue);
}

.library-button-primary-white {
  padding: 15px 60px;
  background-color: var(--color-white);
  color: var(--color-primary-dark-blue);
}

.library-button-secondary-darkblue {
  padding: 12px 57px;
  background-color: transparent;
  border: 3px solid var(--color-primary-dark-blue);
  color: var(--color-primary-dark-blue);
}
.library-button-secondary-darkblue:hover {
  border: 3px solid var(--color-primary-middle-blue);
  color: var(--color-primary-middle-blue);
}

.library-button-secondary-white {
  padding: 12px 57px;
  background-color: transparent;
  border: 3px solid var(--color-white);
  color: var(--color-white);
}

.library-button-disabled {
  opacity: 0.5;
}

/* CONTACT FORM COLUMN */
.contact-form {
  display: inherit;
  grid-column: span 1;
  gap: 24px;

  /* Tablet */
  @media (min-width: 769px) {
    grid-column: span 4;
  }
}

.contact-form-column {
  position: relative;
  display: inherit;
  gap: 24px;
  grid-column: span 2;

  /* Tablet */
  @media (min-width: 769px) {
    grid-column: 1 / span 4;
  }

  .input-field {
    grid-column: span 4;

    /* Tablet */
    @media (min-width: 769px) {
      grid-column: span 2;
    }
  }
  .text-area {
    grid-column: span 4;
    position: relative;
    transition: all 0.3s ease-in-out;

    .input-field-label {
      position: absolute;
      top: 90%;
    }
    .input-field-label-active {
      top: -10%;
    }
  }

  .text-area textarea {
    width: 95%;
  }
  .text-area input {
    width: 100%;
  }
  .text-area textarea,
  .text-area input {
    height: 180px;
    border: none;
    outline: none;

    resize: none; /* Prevent resizing */
    display: flex;
    align-items: flex-end;

    /* Tablet */
    @media (min-width: 769px) {
      height: 174px;
    }
  }
}

.error {
  position: absolute;
  top: 20px;
  color: red;
  font-size: var(--font-size-small);
  margin-top: 5px;
}

.clear {
  position: absolute;
  top: 0;
  right: 0;
  color: var(--color-neutral-dark-grey);
  font-size: var(--font-size-small);
  cursor: pointer;
}

.contact-form-column-button {
  grid-column: 1 / span 2;

  /* Tablet */
  @media (min-width: 769px) {
    grid-column: 3 / span 2;
  }
}

.contact-form-column a {
  margin-top: 24px;
}

/* LINK */
.library-link {
  color: var(--color-black);
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 8px;
  width: fit-content;
}

.library-link .arrow-right {
  padding-right: 15px;
  width: 16px;
  height: 16px;
}

.library-link svg {
  width: 16px;
}

.library-link:hover {
  color: var(--color-primary-middle-blue);

  svg path {
    stroke: var(--color-primary-middle-blue);
  }
}

/* PARTICLES */

/* Fullscreen container */
#particle-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: -1;
}

#particle-container.dark {
  background: linear-gradient(135deg, #282c34, #2c3e50);
}
#particle-container.light {
  background: linear-gradient(135deg, #f5f5f5, #f5f5f5);
}
