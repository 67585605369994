.c7-video-wrapper.grid-margin {
  grid-column: 1 / span 4;

  /* Desktop */
  @media (min-width: 1280px) {
    grid-column: 1 / span 12;
    padding: 0 32px;
    margin: 0;
  }

  /* XLDesktop */
  @media (min-width: 1440px) {
    padding: 0;
  }

  .c7-video {
    max-width: 1440px;
    width: 100%;
    cursor: pointer;
  }
}

.aspect-16-9 {
  aspect-ratio: 16/9;
}
.aspect-3-4 {
  aspect-ratio: 3/4;
}
.aspect-4-3 {
  aspect-ratio: 4/3;
}
