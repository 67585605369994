.t1-teaser {
  grid-column: 1 / span 4;

  /* Desktop */
  @media (min-width: 1280px) {
    grid-column: 3 / span 8;
  }

  h2,
  .copy-default {
    text-align: center;
  }
  h2 {
    padding-bottom: 24px;
  }
  .copy-default {
    padding-bottom: 32px;
  }
}

.t1-teaser-projects {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 24px;
  
  .grid-margin {
    margin: 0;
  }
  
  /* Tablet */
  @media (min-width: 769px) {
    grid-template-columns: 1fr 1fr;
    flex-wrap: nowrap;

    .image-first,
    .text-first {
      flex: 1;
    }
  }

  .image-first,
  .text-first {
    grid-column: span 1;
    display: block;

    .image-block > * {
      aspect-ratio: 3/4;
    }

    .image-block,
    .text-block {
      margin-top: 0;
      padding-bottom: 0;
    }

    .text-block {
      .overline {
        padding-top: 24px;
        padding-bottom: 16px;
      }
      .c3-text-image-headline {
        text-align: left;
        padding-bottom: 24px;
        margin: 0;
      }
      .copy {
        text-align: left;
        p {
          padding-bottom: 24px;
          margin: 0;
        }
      }
      .copy-default {
        padding-bottom: 0;
      }
      .library-link {
        padding-bottom: 32px;
      }
    }
  }
}
