.c9-tags {
  grid-column: 1 / span 4;

  /* Desktop */
  @media (min-width: 1280px) {
    grid-column: 1 / span 12;
  }

  h2 {
    margin: 0 0 32px 0;
  }
  h3 {
    margin: 0 0 11px 0;
  }
}
.c9-tag-group {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.c9-tag {
  display: flex;
  white-space: nowrap;
  gap: 8px;
  padding: 8px 16px;
  background: var(--color-neutral-grey);
  border-radius: 50px;

  img {
    width: 16px;
    object-fit: contain;
  }
}
