footer {
  border-top: 1px solid var(--color-neutral-grey);
}
.f2-footer {
  display: flex;
  padding: 16px 1rem;
  align-items: center;
  justify-content: end;
  max-width: 1440px;
  margin: 0 auto;
  gap: 20px;
  flex-wrap: wrap;

  /* XLDesktop */
  @media (min-width: 1440px) {
    padding: 16px;
  }

  a {
    color: inherit;
  }
}
