.c5-image-slider {
  position: relative;
  height: 100%;
  width: 100vw;
  grid-column: 1 / span 4;
  margin-bottom: 20px;

  /* Desktop */
  @media (min-width: 1280px) {
    grid-column: 1 / span 12;
    left: 50%;
    margin-left: -50vw;
  }
}

.custom-navigation {
  position: relative;
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;

  /* Tablet */
  @media (min-width: 769px) {
    padding: 24px;
  }

  /* Desktop */
  @media (min-width: 1280px) {
    padding: 32px;
  }

  /* XLDesktop */
  @media (min-width: 1440px) {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    padding: 32px 0;
  }
}

.custom-navigation-arrows {
  display: flex;
  gap: 30px;

  /* XLDesktop */
  @media (min-width: 1440px) {
    grid-column: 10;
    justify-content: end;
  }
}

.custom-navigation .swiper-button-next {
  width: 24px;
  position: relative;
  height: fit-content;
  margin: 0;
}
.custom-navigation .swiper-button-prev {
  width: 24px;
  position: relative;
  height: fit-content;
  margin: 0;
  rotate: 180deg;
}

.custom-navigation .swiper-button-prev::after {
  display: none;
}
.custom-navigation .swiper-button-next::after {
  display: none;
}

.slide-indicators {
  display: flex;
  gap: 8px;

  /* XLDesktop */
  @media (min-width: 1440px) {
    grid-column: 3;
  }
}
.slide-indicator {
  width: 24px;
  height: 4px;
  background-color: var(--color-neutral-middle-grey);
}
.slide-indicator.active {
  background-color: var(--color-black);
}

.c5-image-slider-item {
  height: 100%;
  .c4-image {
    aspect-ratio: auto;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
