.c1-stage {
  position: relative;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  margin-bottom: 16px;
  grid-column: 1 / span 4;
  height: 660px;
  width: 100vw;

  /* Tablet */
  @media (min-width: 769px) {
    height: 577px;
  }
  /* Desktop */
  @media (min-width: 1280px) {
    height: 720px;
    grid-column: 1 / span 12;
    max-width: 1440px;
  }

  /* XLDesktop */
  @media (min-width: 1440px) {
    height: 810px;
  }

  .c1-stage-image {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    object-position: center;
    aspect-ratio: 3/4;
  
    /* Tablet */
    @media (min-width: 769px) {
      aspect-ratio: 4/3;
    }
    /* Desktop */
    @media (min-width: 1280px) {
      aspect-ratio: 16/9;
    }
  }

  .c1-stage-image-cover {
    object-fit: cover;
  }
  .c1-stage-image-contain {
    position: relative;
    height: auto;
    object-fit: contain;
    aspect-ratio: auto;
    grid-column: span 12;
  }
}

.c1-stage-height-auto {
  height: auto;
}

.desktop-text-wrapper {
  background: var(--color-white);

  height: fit-content;
  align-self: end;

  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 10px;
  grid-column: 1 / span 12;
  grid-row: 2 / span 1;

  /* Tablet */
  @media (min-width: 769px) {
    background: rgba(255, 255, 255, 0.8);
    padding: 32px;
    width: 100%;
    max-width: 493px;
    grid-column: 2 / span 4;
    justify-self: center;
  }

  /* XLDesktop */
  @media (min-width: 1440px) {
    grid-column: 2 / span 4;
  }
}