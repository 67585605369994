.c2-text {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column: 1 / span 4;
  margin: 0 16px;

  /* Tablet */
  @media (min-width: 769px) {
    margin: 0 32px;
  }

  /* Desktop */
  @media (min-width: 1280px) {
    grid-template-columns: repeat(12, 1fr);
    grid-column: span 12;
    margin: 0;
  }
}

.c2-text h1,
.c2-text .copy-default {
  grid-column: span 4;

  /* Desktop */
  @media (min-width: 1280px) {
    grid-column: 3 / span 8;
  }
}

.c2-text .library-link,
.c2-text .library-button {
  margin-top: 16px;
  grid-column: span 4;

  /* Tablet */
  @media (min-width: 769px) {
    grid-column: span 3;
  }

  /* Desktop */
  @media (min-width: 1280px) {
    grid-column: 3 / span 8;
  }
}
