.c4-image {
  width: 100%;
  object-fit: cover;
  height: auto;
  aspect-ratio: 1/1;
}

.circle-image {
  border-radius: 50%;
}
