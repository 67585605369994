.overlay-open {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.overlay-slide-up {
  animation: slideUp 0.7s ease-in-out forwards;
}

@keyframes slideUp {
  0% {
    height: 100%;
  }
  100% {
    height: 0;
  }
}

.overlay-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay-content-headline {
  opacity: 0;
  animation: fadeIn 1s ease-in-out 0.5s forwards;
  animation-delay: 1s;
}

.close-entrycard {
  position: absolute;
  cursor: pointer;
  right: 0;
  bottom: 10px;
  rotate: 90deg;
  width: 30px;
  padding: 30px;

  opacity: 0;
  animation: fadeIn 1s ease-in-out 0.5s forwards;
  animation-delay: 1s;

  svg {
    height: 30px;
    width: 30px;
    animation: scroll 2s ease-in-out infinite;
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


.close-entrycard.light {
  color: var(--color-black);

  svg {
        path {
      stroke: var(--color-black);
    }
  }
}
.close-entrycard.dark {
  color: var(--color-white);

  svg {
        path {
      stroke: var(--color-white);
    }
  }
}

.close-entrycard.hide {
  svg {
    opacity: 0;
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-50px);
  }
  100% {
    transform: translateX(0);
  }
}
